.App {
  text-align: center;
  background-color: #CAC4CE;

}

.App-logo {
  width: 100vmin;
  pointer-events: none;
}

.App-header {
  background-color: #CAC4CE;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: relative;
}

.App-header::before {
  content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.106);
}

.App-link {
  color: #61dafb;
}

.text {
  color: #483C46;
  padding-bottom: 25px;
}

.body {
  background-color: #CAC4CE;
  height: 100%;
  min-width: auto;
  padding-top: 50px;
}

.footer {
  background-color: black;
  margin-top: 40px;
  color: white;
}
